var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Training Center ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 45 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Masukkan Nama","state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"p-0",attrs:{"label":"Skill Role","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Skill Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"placeholder":"Pilih Skill Role","label":'name',"clearable":true,"options":_vm.listSkill,"reduce":function (skill) { return skill.id; }},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kapasitas","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Kapasitas","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Contoh: 80","state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize},model:{value:(_vm.capacity),callback:function ($$v) {_vm.capacity=$$v},expression:"capacity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Lokasi","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option.subdistrict_id; },"options":_vm.listSubdistrict,"state":errors.length > 0 || _vm.submitErrors.province_id
                            ? false
                            : null,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchSubdistrict(search) : ''; }},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
                            var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(((option.subdistrict_name) + ", " + (option.district_name) + ", " + (option.regency_name) + ", " + (option.province_name)))+" ")])]}}],null,true),model:{value:(_vm.subdistrictId),callback:function ($$v) {_vm.subdistrictId=$$v},expression:"subdistrictId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.province_id))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Detail Lokasi","label-cols-md":"4","label-class":"font-medium text-7 text-black"}},[_c('validation-provider',{attrs:{"name":"Detail Lokasi","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Masukkan alamat lengkap kamu\ncontoh : jl. no desa, rt, rw","state":errors.length > 0 || _vm.submitErrors.address ? false : null},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }