<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Training Center
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 45 }"
                    >
                      <b-form-input
                        v-model="name"
                        placeholder="Masukkan Nama"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Skill Role"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Skill Role`"
                      rules="required"
                    >
                      <v-select
                        class="w-100"
                        placeholder="Pilih Skill Role"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkill"
                        :reduce="skill => skill.id"
                        v-model="skillId"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Kapasitas"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kapasitas"
                      rules="required|numeric"
                    >
                      <b-form-input
                        v-model="capacity"
                        placeholder="Contoh: 80"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Lokasi"
                      rules="required"
                    >
                      <v-select
                        v-model="subdistrictId"
                        :reduce="option => option.subdistrict_id"
                        :options="listSubdistrict"
                        :state="
                          errors.length > 0 || submitErrors.province_id
                            ? false
                            : null
                        "
                        placeholder="Ketik untuk mencari..."
                        @search="(search) => search.length > 0 ? onSearchSubdistrict(search) : ''"
                      >
                        <template
                          #selected-option-container="{ option }"
                        >
                          <div class="vs__selected">
                            {{ `${option.subdistrict_name}, ${option.district_name}, ${option.regency_name}, ${option.province_name}` }}
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{
                        errors[0] || submitErrors.province_id
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Detail Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Detail Lokasi"
                      rules="required|min:5"
                    >
                      <b-form-textarea
                        placeholder="Masukkan alamat lengkap kamu
contoh : jl. no desa, rt, rw"
                        v-model="address"
                        :state="
                          errors.length > 0 || submitErrors.address ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.address
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Simpan
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      detailTrainingCenter: {},

      name: '',
      capacity: '',
      address: '',
      subdistrictId: '',
      listSubdistrict: [],
      listSkill: [],
      skillId: null,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListSkills()
    this.getDetailTrainingCenter()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        name: this.name,
        skill_id: Number.isInteger(this.skillId) ? this.skillId : this.detailTrainingCenter.skill_id,
        capacity: parseInt(this.capacity, 10),
        subdistrict_id: (!Number.isInteger(this.subdistrictId)) ? this.detailTrainingCenter.subdistrict_id : this.subdistrictId,
        address: this.address,
      }

      const url = `v1/training_centers/${this.id}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'training-center' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getDetailTrainingCenter() {
      this.offset = 0
      const url = `v1/training_centers/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailTrainingCenter = await data

          this.loadForm()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.meta.message,
              variant: 'danger',
            },
          }, 1000)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    toQuiz(params) {
      this.idTalent = params
      this.currentStep = 2
      this.buttonBackActive = false
    },
    handleBackFromQuiz() {
      this.currentStep = 1
      this.buttonBackActive = true
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
    alertSuccess(text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Success',
            text,
            variant: 'success',
            icon: 'CheckIcon',
          },
        },
        { timeout: 2500 },
      )
    },
    loadForm() {
      this.name = this.detailTrainingCenter.name
      this.skillId = this.detailTrainingCenter.skill_name
      this.capacity = this.detailTrainingCenter.capacity
      this.address = this.detailTrainingCenter.address

      this.subdistrictId = {
        district_name: this.detailTrainingCenter.district_name,
        province_name: this.detailTrainingCenter.province_name,
        regency_name: this.detailTrainingCenter.regency_name,
        subdistrict_id: this.detailTrainingCenter.subdistrict_id,
        subdistrict_name: this.detailTrainingCenter.subdistrict_name,
        label: `${this.detailTrainingCenter.subdistrict_name}, ${this.detailTrainingCenter.district_name}, ${this.detailTrainingCenter.regency_name}, ${this.detailTrainingCenter.province_name}`,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
</style>
